<template>
  <div class="container">
    <img class="phone-top" src="../imgs/phone-top2.png" alt="">
    <!--<div class="rule info" v-if="list.content">
   <h4>
      -活动介绍-
    </h4>
    <div class="content" v-html="list.content"></div>
  </div> -->
    <div class="info">
      <div class="item p_r">
        <label for="phone"><span>手机号</span><input id="phone" type="tel" v-model="phoneNumber" maxlength="11" /></label>
        <img class="phone-delete" src="../imgs/ski_delete.png" alt="" @click="phoneNumber = ''">
      </div>
      <div class="item">
        <label for="code"><span>验证码</span><input id="code" class="code" type="text" v-model="verifyCode" maxlength="6" /></label>
        <a href="javascript:;" @click="getCode" :class="geting ? 'disable' : ''">{{getCodeText}}</a>
      </div>
      <div class="tips">
        <van-checkbox v-model="checked" checked-color="#40D368">阅读并同意<span class="red_tip" @click.stop="openPop('fwtk')">《服务协议》</span>和<span class="red_tip" @click.stop="openPop('yszc')">《隐私政策》</span>
        </van-checkbox>
      </div>
      <div class="btn">
        <a href="javascript:;" @click="submit">登录</a>
      </div>
    </div>
    <!-- 弹窗 -->
    <van-popup round v-model:show="showPop">
      <div class="pop">
        <div class="tt">{{content.title}}</div>
        <div class="content" v-html="content.content"></div>
        <div class="close" @click="showPop = false">关闭</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import { Toast, Checkbox, Popup } from 'vant';
  import apis from '../utils/apis';
  var phoneNumberVerification = /^1[3456789]\d{9}$/;
  var waiting = localStorage.getItem('waiting');
  export default {
    name: 'skiPhone',
    components: {
      'van-checkbox': Checkbox,
      'van-popup': Popup,
    },
    data() {
      return {
        urls: {
          getLoginCode: 'getLoginCode', // 获取短信
          updateUserPhone: 'userInfo/updateUserPhone', // 绑定手机号
        },
        phoneNumber: '', // 手机号码
        verifyCode: '', // 验证码
        geting: false, // 验证码获取中
        getCodeText: '获取验证码', // 按钮文本
        checked: false,
        list: {}, //活动规则
        content: {}, //服务协议
        showPop: false, // 弹窗
      };
    },
    created() {
      this.getInfo();
    },
    mounted() {
      console.log('绑定手机');
      if (waiting) {
        var myDate = new Date();
        if (myDate.getTime() - waiting < 60000) {
          this.geting = true;
          this.leftTime(myDate.getTime());
        } else {
          localStorage.removeItem('waiting');
        }
      }
    },
    methods: {
      // 保存
      getCode() {
        let me = this;
        if (this.geting) return;
        if (this.phoneNumber.replace(/\s/g, '') == '') {
          Toast('请输入手机号码');
          return;
        }
        if (!phoneNumberVerification.test(this.phoneNumber)) {
          Toast('手机号码输入有误');
          return;
        }
        this.geting = true;
        var myDate = new Date();
        this.leftTime(myDate.getTime());
        this.$post({
          url: this.urls.getLoginCode,
          params: {
            phoneNum: this.phoneNumber,
          },
        })
          .then(rsp => {
            if (rsp.status != 1) {
              Toast('获取验证码失败！');
            }
          })
          .catch(error => {
            Toast(error.message);
          });
      },
      // 提交数据
      submit() {
        console.log('提交数据');
        var me = this;
        console.log(this.checked);
        if (this.phoneNumber.replace(/\s/g, '') == '') {
          Toast('请输入手机号码');
          return;
        }
        if (!phoneNumberVerification.test(this.phoneNumber)) {
          Toast('手机号码输入有误');
          return;
        }
        if (this.verifyCode.replace(/\s/g, '') == '') {
          Toast('请输入验证码');
          return;
        }

        if (!this.checked) {
          Toast('请阅读并选择同意活动规则');
          return;
        }
        this.$post({
          url: this.urls.updateUserPhone,
          params: {
            token: this.$global.token,
            terminalCode: 'H5',
            phoneNumber: this.phoneNumber,
            verifyCode: this.verifyCode,
          },
        })
          .then(rsp => {
            if (rsp.status == 1) {
              Toast('绑定成功！');
              //手机号写到app里面
              if (this.$global.modelState) {
                window.android.bindPhone(this.phoneNumber.toString());
              } else {
                this.$iosMethod(function(bridge) {
                  bridge.callHandler('bindPhone', this.phoneNumber.toString(), function(data) {
                    // this.$global.token = data?data:null;
                  })
                })
              }
              setTimeout(() => {
                this.$router.push({
                  path: '/',
                });
              }, 2000);
            }
          })
          .catch(error => {
            Toast(error.message);
          });
      },
      // 获取预定须知
      getInfo() {
        this.$get({
          url: apis.findByColumnCode,
          params: {
            columnCode: 'bxjyhgz',
            token: this.$global.token,
          },
        }).then(rsp => {
          this.list = rsp;
        });
      },
      // 获取服务协议 隐私政策
      getInfoIn(str) {
        this.content = {}
        this.$get({
          url: apis.findByColumnCode,
          params: {
            columnCode: str,
            token: this.$global.token,
          },
        }).then(rsp => {
          this.content = rsp;
        });
      },
      //打开服务协议
      openPop(a) {
        this.showPop = true;
        this.getInfoIn(a);
      },
      // 获取验证码等待倒计时
      leftTime(nowTime) {
        this.geting = true;
        var me = this;
        var count = 60;
        if (waiting && nowTime - waiting < 60000) {
          count = parseInt((60000 - (nowTime - waiting)) / 1000);
        } else {
          localStorage.setItem('waiting', nowTime);
        }
        getSecurityCode();
        var countdown = setInterval(getSecurityCode, 1000);
        function getSecurityCode() {
          me.getCodeText = '重新获取（' + count + '）';
          count--;
          if (count == 0) {
            clearInterval(countdown);
            me.geting = false;
            me.getCodeText = '获取验证码';
            localStorage.removeItem('waiting');
          }
        }
      },
    },
  };
</script>
<style scoped lang="scss">
  .container {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 10px 20px 20px 20px;
    box-sizing: border-box;
    background: url('../imgs/bg2.png') no-repeat center top/cover;
    .img img {
      width: 100%;
    }
    .phone-top {
      width: 100%;
      margin-top: 30px;
    }
    .rule {
      h4 {
        line-height: 40px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        text-align: center;
      }
    }
    .p_r {
      position: relative;
    }
    .info {
      .phone-delete {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 0;
        top: 15px;
        cursor: pointer;
      }
      background: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      border-radius: 10px;
      margin-top: 14px;
    }
    .title {
      font-weight: bold;
      font-size: 18px;
      margin-top: 10px;
      img {
        vertical-align: text-top;
        margin-left: 5px;
        height: 18px;
      }
    }
    .item {
      width: 100%;
      height: 45px;
      padding: 5px 0;
      box-sizing: border-box;
      position: relative;
      border-bottom: 1px solid #c7c7c7;
      margin-bottom: 15px;
      color: #141212;
      label {
        display: block;
        span {
          width: 50px;
          font-size: 16px;
          display: inline-block;
        }
        input {
          width: calc(100% - 60px);
          margin-left: 10px;
          box-sizing: border-box;
          height: 40px;
          padding: 0 10px;
          font-size: 16px;
          line-height: 40px;
          -webkit-appearance: none;
          -webkit-tap-highlight-color: transparent;
          border: none;
          background: none;
        }
        input:focus {
          background: none;
        }
        input.code {
          width: calc(60% - 60px);
        }
      }
      a {
        position: absolute;
        right: 0px;
        top: 5px;
        width: 120px;
        height: 25px;
        border: 1px solid #18cd70;
        border-radius: 13px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #19c36c;
        line-height: 24px;
        text-align: center;
      }
      a.disable {
        color: #999;
        border-color: #999;
      }
    }
    .tips {
      .red_tip {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        text-decoration: underline;
        font-style: italic;
      }
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 14px;
      color: #3f3f3f;
      background: #fff;
    }
    .btn {
      margin-top: 10;
      a {
        display: block;
        text-align: center;
        color: #fff;
        font-size: 18px;
        line-height: 50px;
        // background: linear-gradient(to right, #7ddb4f, #21dd53);
        background: #40d368;
        border-radius: 10px;
      }
    }
    ::v-deep .van-popup {
      width: 80%;
    }
    .pop {
      .tt {
        text-align: center;
        line-height: 50px;
        background: #39aeea;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
      .content {
        height: 60vh;
        overflow-y: auto;
        padding: 10px;
        box-sizing: border-box;
      }
      .close {
        width: 60%;
        text-align: center;
        margin: 10px auto;
        background: #39aeea;
        border-radius: 34px;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
      }
    }
  }
</style>
